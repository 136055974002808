.print_body_container {
  padding: 0 1rem;
}
.print_header {
  font-size: 30px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: var(--main-color);
}
.print_container {
  padding: 10px;
}

.print_driver {
  text-align: right;
}

.print_user {
  text-align: left;
}
.print_body_container p {
  font-weight: 700;
}
.print_body_container span {
  font-weight: 500;
}
.ant-table.ant-table-small {
  font-size: 12px;
  font-weight: 500;
}
