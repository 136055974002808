
  .ant-input-affix-wrapper {
    border: none;
    border-radius: 10px;
    height: 35px;
    padding: 0 15px;
    margin-left: 0.75rem;
    background-color: #fff !important;
  }
  
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }