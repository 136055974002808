table {
  border-collapse: collapse !important;
  border-spacing: 0;
  width: 100%;
}

.ant-table-thead th {
  text-align: left;
  padding: 10px;
  border-right: 1px solid var(--table-border) !important;
  border-bottom: 1px solid var(--table-border) !important;
}
.ant-table-tbody td {
  text-align: left;
  padding: 10px;
  border-right: 1px solid var(--table-border) !important;
  border-bottom: 1px solid var(--table-border) !important;
}
.ant-table-thead,
.ant-table-tbody tr {
  cursor: pointer;
  border-top: 1px solid var(--table-border) !important;
  border-bottom: 1px solid var(--table-border) !important;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 0px !important;
}
/* tr:last-child,
  td:last-child,
  th:last-child {
    border: none !important;
  } */
tr:nth-child(even) {
  background-color: #f3f6f9;
}
tr:nth-child(odd) {
  background-color: white;
}
thead tr:first-child {
  background-color: var(--main-color) !important;
  color: white;
  border: none !important;
}
.ant-table-summary .ant-table-cell,
.ant-table-summary .ant-table-cell span {
  background-color: var(--main-color);
  color: white;
}
.ant-table-thead {
  background-color: var(--main-color) !important;
  color: white;
  position: sticky;
  z-index: 1;
  top: 0px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.Table_div {
  overflow: auto;
  background-color: white;
  margin: 15px auto;
}
.ant-table.ant-table-small .ant-table-thead > tr > th:first-child,
.ant-table.ant-table-small .ant-table-tbody > tr > td:first-child {
  padding: 0 !important;
}
.ant-table.ant-table-small .ant-table-thead > tr > th {
  background-color: var(--main-color) !important;
  -webkit-print-color-adjust: exact;
  border-bottom: 1px solid var(--table-border) !important;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding-left: 10px !important;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: none !important;
}
.table_head {
  background-color: var(--main-color);
  color: white;
  padding: 2px 2px;
  text-align: center;
}

.main_table {
  margin-top: 10px;
  width: calc(100% - 10px);
}

.table_img {
  height: 20px;
}

.clickRowStyl {
  background-color: #add8e6 !important;
}

.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #add8e6 !important;
}
.ant-table-cell {
  border-bottom: none !important;
  padding: 2px 8px !important;
}
.ant-table-content {
  border-radius: var(--input-radius) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.ant-table-tbody tr:first-child {
  border-top: none !important;
}
.ant-table-summary .ant-table-cell {
  text-align: right;
  /* display: flex !important;
    justify-content: flex-end !important; */
}

.naklad-table .tbody-th-bg-naklad {
  background-color: #90ffde8f !important;
  border-left: 1px solid #000 !important;
  border-top-color: #000 !important;
}
.naklad-table .tbody-th-bg-naklad td {
  font-weight: 700 !important;
}
.naklad-table .thead-th-bg-naklad {
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
  background-color: #ffff90 !important;
  color: #000;
}

.naklad-table td,
.naklad-table th {
  padding: 0.5px 5px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #000;
}

.naklad-table tr {
  background-color: #fff !important;
}

.naklad-table {
  font-size: 9pt;
  margin-top: 2rem;
}
