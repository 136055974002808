.form_input .ant-input {
  border-radius: var(--input-radius) !important;
  background-color: var(--input-bg) !important;
}
.ant-input-number {
  border-radius: var(--input-radius) !important;
  background-color: var(--input-bg) !important;
  width: 100%;
  border: none !important;
}

.ant-select .ant-select-selector {
  display: block !important;

  background-color: var(--input-bg) !important;
  border: none !important;
  border-radius: var(--input-radius) !important;
}

.ant-select {
  width: 100% !important;
}

.react-tel-input input {
  outline-color: #40a9ff !important;
  outline-width: 1px !important;
}

.label {
  color: var(--label-color);
}
.ant-modal-header {
  padding: 12px 24px !important;
}
.grid_form {
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
}
.qoldiq_red {
  border: 1px solid #ff7875 !important;
}
