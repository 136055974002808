.form_container {
    padding-bottom: 30px;
}
.form-btn {
    display: flex;
    align-items: center;
    border-radius: var(--input-radius);
    padding: 15px 30px;
}
.btn-back {
    background-color: var(--input-bg) !important;
    color: var(--main-color);
}
.btn-submit {
    background-color: var(--main-color) !important;
    color: white;
}
.modal-title {
    color: var(--main-color);
}
.ant-modal-header {
    background-color: var(--input-bg);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.ant-modal-content {
    border-radius: 12px;
}
.ant-modal-close-x {
    font-size: 20px !important;
    color: #FF4B4B !important;
}
.ant-modal-body {
    padding: 15px 20px !important;
}
.form_input {
    min-width: 115px;
}
form {
    overflow: auto;
}
.currency_input {
    width: 100%;
    border-radius: var(--input-radius);
    border: 1px solid #d9d9d9;
    background-color: var(--input-bg);
    padding: 4px 11px;
}
.currency_input:focus {
    outline: none;
}